<template>
  <div>
    <!-- <v-container fluid class="down-top-padding"> -->
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card>
                <v-card-title class="elevation-1">
                  Categories
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      solo
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-row justify="end" class="px-5">
                    <v-dialog
                      :retain-focus="false"
                      v-model="dialog"
                      persistent
                      max-width="600px"
                      :key="dialog"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="green" v-bind="attrs" v-on="on">
                          Add
                        </v-btn>
                      </template>
                      <v-card>
                        <v-form v-model="addcat" ref="addcat">
                          <v-card-title>
                            <span class="headline">Add Category</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="name"
                                    label="Category Name"
                                    :rules="[rules.required]"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="listingOrder"
                                    label="Listing Order"
                                    :rules="[rules.required]"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-combobox
                                    v-model="seotags"
                                    :items="items"
                                    chips
                                    clearable
                                    label="SEO Tags"
                                    multiple
                                    class="pt-3"
                                  >
                                    <template
                                      v-slot:selection="{
                                        attrs,
                                        item,
                                        select,
                                        selected,
                                      }"
                                    >
                                      <v-chip
                                        v-bind="attrs"
                                        :input-value="selected"
                                        close
                                        @click="select"
                                        @click:close="remove(item)"
                                      >
                                        <strong>{{ item }}</strong
                                        >&nbsp;
                                      </v-chip>
                                    </template>
                                  </v-combobox>
                                </v-col>
                                <v-col cols="12">
                                  <v-combobox
                                    v-model="keywords"
                                    :items="keywordItems"
                                    chips
                                    clearable
                                    label="Keywords"
                                    multiple
                                    class="pt-3"
                                  >
                                    <template
                                      v-slot:selection="{
                                        attrs,
                                        item,
                                        select,
                                        selected,
                                      }"
                                    >
                                      <v-chip
                                        v-bind="attrs"
                                        :input-value="selected"
                                        close
                                        @click="select"
                                        @click:close="remove(item)"
                                      >
                                        <strong>{{ item }}</strong
                                        >&nbsp;
                                      </v-chip>
                                    </template>
                                  </v-combobox>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="fbpixel"
                                    label="Facebook pixel"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                  <v-file-input
                                    v-model="categoryImg"
                                    @change="getFiles"
                                    label="Image"
                                    outlined
                                    dense
                                  ></v-file-input>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="dialogclose()"
                            >
                              Close
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!addcat"
                              @click="categoryadd()"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="category"
                  :search="search"
                  hide-default-footer
                  class="elevation-1"
                >
                 <template v-slot:[`item.image`]="{ item }">
                  <img
                      v-bind:src="baseURL + item.image"
                      width="auto"
                      height="50px"
                    />
                 </template>
                  <template v-slot:[`item._id`]="{ item }">
                    <v-icon small class="mr-2" @click="editcat(item)">
                      mdi-pencil
                    </v-icon>
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-2"
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          Category?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:top>
                    <v-dialog
                      v-model="editdialog"
                      :retain-focus="false"
                      max-width="600px"
                    >
                      <v-card>
                        <v-card-title>
                          <span class="headline">Edit Category </span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editingitem.name"
                                  label="Category Name"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editingitem.listingOrder"
                                  label="Listing Order"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-combobox
                                  v-model="editingitem.seotags"
                                  :items="items"
                                  chips
                                  clearable
                                  label="SEO Tags"
                                  multiple
                                  class="pt-3"
                                >
                                  <template
                                    v-slot:selection="{
                                      attrs,
                                      item,
                                      select,
                                      selected,
                                    }"
                                  >
                                    <v-chip
                                      v-bind="attrs"
                                      :input-value="selected"
                                      close
                                      @click="select"
                                      @click:close="remove(item)"
                                    >
                                      <strong>{{ item }}</strong
                                      >&nbsp;
                                    </v-chip>
                                  </template>
                                </v-combobox>
                              </v-col>
                              <v-col cols="12">
                                <v-combobox
                                  v-model="editingitem.keywords"
                                  :items="keywordItems"
                                  chips
                                  clearable
                                  label="Keywords"
                                  multiple
                                  class="pt-3"
                                >
                                  <template
                                    v-slot:selection="{
                                      attrs,
                                      item,
                                      select,
                                      selected,
                                    }"
                                  >
                                    <v-chip
                                      v-bind="attrs"
                                      :input-value="selected"
                                      close
                                      @click="select"
                                      @click:close="remove(item)"
                                    >
                                      <strong>{{ item }}</strong
                                      >&nbsp;
                                    </v-chip>
                                  </template>
                                </v-combobox>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editingitem.fbpixel"
                                  label="Facebook pixel"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-img
                                  height="150"
                                  width="200"
                                  contain
                                  :src="baseURL + editingitem.image"
                                >
                                </v-img>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-file-input
                                  v-model="categoryImg"
                                  @change="getFiles"
                                  label="Image"
                                  outlined
                                  dense
                                ></v-file-input>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="editdialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="edit()">
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-data-table>
                <!-- <div class="pt-2 blue-grey lighten-5">
                  <v-pagination
                    :length="perPage"
                    :total-rows="totalData"
                    v-model="currentPage"
                  ></v-pagination>
                </div> -->
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <!-- </v-container> -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: {},
      name: "",
      listingOrder:"",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      items: ["meta"],
      keywordItems: ["Best Selling", "User Friendly"],
      seotags: null,
      title: null,
      fbpixel: null,
      keywords: [],
      categoryImg: null,
      cId: "",
      attachments: null,
      attachmentsformData: new FormData(),
      headers: [
        { text: "Category Name", value: "name" },
        { text: "Image", value: "image" },
        // { text: "Username", value: "username" },
        // { text: "Phone", value: "phone" },
        // { text: "Email", value: "email" },
        // { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      category: [],
      data: [],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // },
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.admin = [];
    },
    getFiles() {
      var selectedFiles = this.categoryImg;
      if (this.categoryImg) {
        this.msg = null;
        this.attachments = selectedFiles;
      }
    },
    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/category/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/category/getlist",

        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.category = response.data.data;

          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    categoryadd() {
      var data = {};
      data["name"] = this.name;
      data["listingOrder"]=this.listingOrder;
      axios({
        url: "/category/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.cId = response.data.id;
            if (this.categoryImg) {
              this.uploadattachments();
            }
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadattachments() {
      this.categoryImg = null;
      this.attachmentsformData.append("image", this.attachments);
      this.attachmentsformData.append("id", this.cId);
      axios({
        method: "POST",
        url: "/category/UploadImagesingle",
        data: this.attachmentsformData,

        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.attachmentsformData = new FormData();
            this.msg = "Upload Successfully";
            this.showsnackbar = true;
            var myTimeout = setTimeout(this.getData, 2000);
            console.log(myTimeout);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["name"] = this.editingitem.name;
      data["seotags"] = this.editingitem.seotags;
      data["fbpixel"] = this.editingitem.fbpixel;
      data["keywords"] = this.editingitem.keywords;
      data["listingOrder"]=this.editingitem.listingOrder;
      axios({
        url: "/category/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            if (this.categoryImg) {
              //   this.attachmentsformData.append("icon", this.attachments);
              this.uploadattachments1();
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadattachments1() {
      this.categoryImg = null;
      this.attachmentsformData.append("image", this.attachments);
      this.attachmentsformData.append("id", this.editingitem._id);
      axios({
        method: "POST",
        url: "/category/UploadImagesingle",
        data: this.attachmentsformData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.attachmentsformData = new FormData();
            this.msg = "Upload Successfully";
            this.showsnackbar = true;
            var myTimeout = setTimeout(this.getData, 2000);
            console.log(myTimeout);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.table-striped:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
